import { Injectable } from '@angular/core';

import { BehaviorSubject, map, Observable, tap } from 'rxjs';

import { ListMessageTypesPayload, MessageType, TradingPartner } from '../../models';
import { TradingPartnersApiService } from '../../services';

@Injectable({ providedIn: 'root' })
export class TradingPartnersService {
  get tradingPartnerList$(): Observable<TradingPartner[]> {
    return this.#partnerList$.asObservable();
  }

  get transactionTypeList$(): Observable<string[]> {
    return this.#transactionTypeList$.asObservable();
  }

  readonly #partnerList$ = new BehaviorSubject<TradingPartner[]>([]);
  readonly #transactionTypeList$ = new BehaviorSubject<string[]>([]);

  constructor(private tradingPartnersApiService: TradingPartnersApiService) {}

  getAll(): Observable<TradingPartner[]> {
    return this.tradingPartnersApiService.list().pipe(
      map((partners: TradingPartner[]) => partners.sort((a,b) => a.name.localeCompare(b.name))),
      tap((partners) => this.#partnerList$.next(partners))
    );
  }

  getTransactionTypes(payload?: ListMessageTypesPayload): Observable<string[]> {
    return this.tradingPartnersApiService.listMessageTypes(payload).pipe(
      map((transactionTypes: MessageType[]) => {
        const transactionTypeList: string[] = transactionTypes.map(({ transactionType }) => transactionType);

        return Array.from(new Set(transactionTypeList)).sort((a, b) => a.localeCompare(b));
      }),
      tap((transactionTypes) => this.#transactionTypeList$.next(transactionTypes)),
    );
  }
}
