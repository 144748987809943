import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, tap } from 'rxjs';

import { Role } from '../../models';
import { RolesApiService } from '../../services';

@Injectable({ providedIn: 'root' })
export class RolesService {
  get roleList$(): Observable<Role[]> {
    return this.#roleList$.asObservable();
  }

  readonly #roleList$ = new BehaviorSubject<Role[]>([]);

  constructor(private rolesApiService: RolesApiService) {}

  getAll(): Observable<Role[]> {
    return this.rolesApiService.list().pipe(tap((roles) => this.#roleList$.next(roles)));
  }
}
