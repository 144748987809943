import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { catchError, map, Observable } from 'rxjs';

import { ActorService } from '../services';

@Injectable({ providedIn: 'root' })
export class ActorGuard {
  constructor(
    private actor: ActorService,
    private router: Router,
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.actor.getUserInfo().pipe(
      map(() => true),
      catchError((err) => {
        console.log(err);
        void this.router.navigate(['/error'], { queryParams: { type: 'userinfo' } });

        throw new Error('user info error');
      }),
    );
  }
}
