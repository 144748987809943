import { Exclude, Expose } from 'class-transformer';

import { dataCollectionHistoryStatusMap } from '@core/constants';
import { DataCollectionTemplateId, WarehouseUploadStatus, WarehouseUploadType } from '@core/enums';
import { IDataCollection } from '@core/models';

import { Badge, BadgeType } from '../badge';

@Exclude()
export class DataCollection implements IDataCollection {
  @Expose()
    id!: string;

  @Expose()
    reportingDate!: string;

  @Expose()
    filename!: string | null;

  @Expose()
    userName!: string;

  @Expose()
    status!: WarehouseUploadStatus;

  @Expose()
    type!: WarehouseUploadType;

  @Expose()
    createdAt!: string;

  @Expose()
    error!: string[] | null;

  @Expose()
    templateId!: string | null;

  @Expose()
    originalStatus!: WarehouseUploadStatus;

  @Expose()
    canDownload?: boolean;

  @Expose()
    warehouseName?: string;

  @Expose()
    warehouseId?: string | null;

  get statusBadge(): Badge<WarehouseUploadStatus> {
    if (this.status === WarehouseUploadStatus.UPLOAD_FAILED
      || this.status === WarehouseUploadStatus.CALCULATION_FAILED) {
      return {
        key: WarehouseUploadStatus.CALCULATION_FAILED,
        value: 'Failed',
        type: BadgeType.ERROR,
      };
    }

    return dataCollectionHistoryStatusMap[this.status];
  }

  get canHandleFailedFile(): boolean {
    return (
      this.originalStatus === WarehouseUploadStatus.CALCULATION_FAILED &&
      this.templateId === DataCollectionTemplateId.TRANSPORT_6
    );
  }
}
