import { Injectable } from '@angular/core';

import { first, Observable } from 'rxjs';

import { ApiService, LoadingService, SnackbarService } from '../services';

const ENDPOINT = 'v1/templates';

@Injectable({ providedIn: 'root' })
export class TemplatesService {
  constructor(
    private api: ApiService,
    private loader: LoadingService,
    private snack: SnackbarService,
  ) {}

  previewTemplate(id: string): Observable<void> {
    return this.api
      .get<void>(`${ENDPOINT}/${id}/preview`)
      .pipe(first(), this.loader.set('preview-template'), this.snack.operator());
  }
}
