import { IStatusCode } from '../models';

export const StatusCodes: IStatusCode = {
  stored_inbound: {
    short: 'Inbound message received',
    long: 'The Hub has received an inbound message and it has been stored for further processing.',
  },
  stored_standard: {
    short: 'Veratrak standard stored',
    long: 'The file was transformed into the Veratrak standard format and stored in the Hub.',
  },
  stored_outbound: {
    short: 'Outbound format stored',
    long: 'The outbound format file was stored in the Hub.',
  },
  stored_emissions: {
    short: 'Emissions format stored',
    long: 'The emissions format file was stored in the Hub.',
  },
  trans_inbound_to_standard: {
    short: 'Inbound format transformed to Veratrak standard',
    long: 'Inbound message was successfully transformed to the Veratrak JSON standard.',
  },
  trans_inbound_to_staging: {
    short: 'Inbound transformed to staging',
    long: 'The inbound message was transformed and stored for future processing.',
  },
  trans_inbound_to_emissions: {
    short: 'Inbound transformed to emissions',
    long: 'The inbound message was transformed and stored for emissions processing.',
  },
  trans_standard_to_outbound: {
    short: 'Veratrak standard transformed to outbound format',
    long: 'The Veratrak standard format file was successfully transformed to the appropriate recipient file format.',
  },
  trans_emissions_to_outbound: {
    short: 'Veratrak emissions transformed to outbound format',
    long: 'The Veratrak emissions format file was successfully transformed to the appropriate recipient file format.',
  },
  sent_outbound: {
    short: 'Outbound message sent',
    long: 'The Hub has successfully sent the outbound format file to the appropriate destination.',
  },
  incorrect_filename: {
    short: 'Incorrect file name format in message',
    long: 'The file name must be in format of {TRANS_TYPE}-{SENDER}-{RECEIVER}-{TIMESTAMP}.{EXTENSION}',
  },
  broker_not_found: {
    short: 'Translation mapping not found',
    long: 'Translation mapping not found for this combination of type, sender and receiver',
  },
  translation_error: {
    short: 'Translation error',
    long: 'An error occurred during the message translation process in the Hub mapping system.',
  },
  outbound_failed: {
    short: 'Outbound error',
    long: 'An error occurred during sending the message to the receiver',
  },
  parser_not_found: {
    short: 'Parser not found',
    long: 'Parser not found for this combination of rules',
  },
  control_not_found: {
    short: "IDOC parser can't find EDI_DC40 segment",
    long: 'EDI_DC40 segment is missing from payload; sender, receiver and message type cannot be determined',
  },
};
