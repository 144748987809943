import { Exclude, Expose } from 'class-transformer';

import { measurementSystemList } from '@core/constants';
import { MeasurementSystem } from '@core/enums';
import { IDataCollectionUserWarehouse, SelectOption } from '@core/models';
import { getDataCollectionTemplateName } from '@core/utils';

@Exclude()
export class DataCollectionUserWarehouse implements IDataCollectionUserWarehouse {
  @Expose()
    canUseTransportTemplate!: boolean;

  @Expose()
    canUseWarehouseTemplate!: boolean;

  @Expose()
    id!: string;

  @Expose()
    measurementSystem!: MeasurementSystem;

  @Expose()
    name!: string;

  @Expose()
    transportEmissionsTemplateId!: string;

  @Expose()
    warehouseEmissionsTemplateId!: string;

  get emissionTemplateList(): SelectOption<string>[] {
    const emissionTemplateList: SelectOption<string>[] = [];

    if (this.canUseWarehouseTemplate) {
      const templateId = this.warehouseEmissionsTemplateId;

      emissionTemplateList.push({ key: templateId, value: getDataCollectionTemplateName(templateId) });
    }

    if (this.canUseTransportTemplate) {
      const templateId = this.transportEmissionsTemplateId;

      emissionTemplateList.push({ key: templateId, value: getDataCollectionTemplateName(templateId) });
    }

    return emissionTemplateList.sort((a, b) => a.value.localeCompare(b.value));
  }

  get selectedMeasurementSystem(): SelectOption<MeasurementSystem> {
    const measurementSystemOption = measurementSystemList.find(({ key }) => key === this.measurementSystem);

    return measurementSystemOption ?? ({} as SelectOption<MeasurementSystem>);
  }
}
