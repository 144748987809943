import { Location } from '@angular/common';
import { Injectable } from '@angular/core';

import { BehaviorSubject, debounceTime, map, withLatestFrom } from 'rxjs';

import { AuthService } from '@auth0/auth0-angular';

@Injectable({ providedIn: 'root' })
export class VeratrakAuthService {
  whitelistUrls: string[] = ['/register', '/register/accept'];

  constructor(
    private authService: AuthService,
    private location: Location,
  ) {
    this.handleUnauthorised();
    this.handleLogoutInAnotherTab();
  }

  handleUnauthorised(): void {
    const url: BehaviorSubject<string> = new BehaviorSubject<string>('');
    this.location.onUrlChange((currentUrl) => url.next(currentUrl));

    this.authService.isAuthenticated$
      .pipe(
        debounceTime(2000),
        withLatestFrom(this.authService.isLoading$, url),
        map(([authenticated, loading, url]: [boolean, boolean, string]) => ({ authenticated, loading, url })),
      )
      .subscribe((val) => {
        if (val.authenticated === false && val.loading === false && !this.urlIsWhitelisted(val.url)) {
          this.logout();
        }
      });
  }

  handleLogoutInAnotherTab(): void {
    localStorage.setItem('logout', 'false');
    window.addEventListener('storage', (storageValue) => {
      if (storageValue.key === 'logout' && storageValue.newValue === 'true') {
        this.cleanStorage();
        window.location.reload();
      }
    });
  }

  logout(): void {
    this.cleanStorage();
    this.authService.logout({ logoutParams: { returnTo: window.location.origin }});
  }

  cleanStorage(): void {
    localStorage.setItem('logout', 'true');
    // Cid stores in sessionStorage and may set wrong Cid on login
    sessionStorage.clear();
  }

  urlIsWhitelisted(url: string): boolean {
    return !!this.whitelistUrls.find((whiteUrl) => url.includes(whiteUrl));
  }
}
