export enum WarehouseUploadStatus {
  PROCESSING = 0,
  SUCCESS = 1,
  UPLOAD_FAILED = 2,
  CALCULATION_FAILED = 3,
}

export enum WarehouseUploadType {
  EMISSIONS = 'emissions',
}

export enum UploadStatus {
  FAILED = 'FAILED',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
  CALCULATION_FAILED = 'CALCULATION_FAILED',
  UPLOAD_FAILED = 'UPLOAD_FAILED',
}

export enum DataCollectionLoaderKey {
  LIST_WAREHOUSE_HISTORY = 'list-warehouse-data-collection-history',
  LIST_USER_HISTORY = 'list-user-data-collection-history',
  LIST_USER_WAREHOUSES = 'list-data-collection-user-warehouses',
  LIST_NOT_REPORTED_MONTH = 'list-data-collection-not-reported-month',
  UPLOAD_WAREHOUSE_HISTORY_FILE = 'upload-warehouse-data-collection-history-file',
  UPLOAD_WAREHOUSE_HISTORY_ENTRIES = 'upload-warehouse-data-collection-history-entries',
  GET_TOTALS_METRICS = 'get-data-collection-totals-metrics',
  GET_METHODS_METRICS = 'get-data-collection-methods-metrics',
  GET_REPORTING_METRICS = 'get-data-collection-reporting-metrics',
  GET_PREVIEW = 'data-collection-preview',
}

export enum DataCollectionTemplateCategory {
  TRANSPORT = 'TRANSPORT',
  WAREHOUSE = 'WAREHOUSE',
}

export enum DataCollectionTemplateFormat {
  MANUAL_ENTRY = 'MANUAL_ENTRY',
  FILE_UPLOAD = 'FILE_UPLOAD',
}

export enum DataCollectionTemplateId {
  TRANSPORT_1 = 'Transport_Self_Reporting_1',
  TRANSPORT_2 = 'Transport_Self_Reporting_2',
  TRANSPORT_3 = 'Transport_Self_Reporting_3',
  TRANSPORT_4 = 'Transport_Calculated_4',
  TRANSPORT_5 = 'Transport_Calculated_5',
  TRANSPORT_6 = 'Transport_Calculated_6',
  WAREHOUSE_1 = 'Warehouse_Self_Reported_1',
  WAREHOUSE_2 = 'Warehouse_Calculated_2',
}
