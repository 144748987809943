import { ComponentType } from '@angular/cdk/overlay';

import { Transport1DialogComponent } from '@feature/templates/transport1';
import { Transport4DialogComponent } from '@feature/templates/transport4';
import { Warehouse1DialogComponent } from '@feature/templates/warehouse1';
import { Warehouse2DialogComponent } from '@feature/templates/warehouse2';
import { Badge, BadgeType } from '@shared/badge';

import {
  DataCollectionTemplateCategory,
  DataCollectionTemplateFormat,
  DataCollectionTemplateId,
  WarehouseUploadType,
  WarehouseUploadStatus,
} from '../enums';
import { DataCollectionTemplate, SelectOption, TemplateComponents } from '../models';

export const MAX_FILE_SIZE = 5000000;

/**
 * Data Collection History Status Map.
 *
 * @constant
 * @type {Record<UploadStatus, Badge<UploadStatus>>}
 */
export const dataCollectionHistoryStatusMap: Record<WarehouseUploadStatus, Badge<WarehouseUploadStatus>> = {
  [WarehouseUploadStatus.SUCCESS]: {
    key: WarehouseUploadStatus.SUCCESS,
    value: 'Success',
    type: BadgeType.SUCCESS,
  },
  [WarehouseUploadStatus.PROCESSING]: {
    key: WarehouseUploadStatus.PROCESSING,
    value: 'Processing',
    type: BadgeType.PROGRESS,
  },
  [WarehouseUploadStatus.CALCULATION_FAILED]: {
    key: WarehouseUploadStatus.CALCULATION_FAILED,
    value: 'Calculation Failed',
    type: BadgeType.ERROR,
  },
  [WarehouseUploadStatus.UPLOAD_FAILED]: {
    key: WarehouseUploadStatus.UPLOAD_FAILED,
    value: 'Upload Failed',
    type: BadgeType.ERROR,
  },
};

/**
 * Constant for Data Collection Template List.
 *
 * @constant
 * @type {DataCollectionTemplate[]}
 */
export const dataCollectionTemplateList: DataCollectionTemplate[] = [
  {
    category: DataCollectionTemplateCategory.TRANSPORT,
    description: 'Input monthly data sorted by temperature class and transport method with calculated emissions.',
    format: DataCollectionTemplateFormat.MANUAL_ENTRY,
    id: DataCollectionTemplateId.TRANSPORT_1,
    name: 'Transport 1 - Monthly Total Volumes per Mode including Emissions (Entry)',
    shortDescription: 'Data, categorized by temperature class and transport method.',
    type: WarehouseUploadType.EMISSIONS,
  },
  {
    category: DataCollectionTemplateCategory.TRANSPORT,
    description: 'Input monthly totals data, calculated emissions, temperature class, and transport method.',
    format: DataCollectionTemplateFormat.FILE_UPLOAD,
    id: DataCollectionTemplateId.TRANSPORT_2,
    name: 'Transport 2 – Weight, Distance per Trip including Emissions (File)',
    shortDescription: 'Totals data for all shipments with calculated emissions.',
    type: WarehouseUploadType.EMISSIONS,
  },
  {
    category: DataCollectionTemplateCategory.TRANSPORT,
    description:
      'Input monthly total data per shipment, calculated emissions, temperature class, and transport method.',
    format: DataCollectionTemplateFormat.FILE_UPLOAD,
    id: DataCollectionTemplateId.TRANSPORT_3,
    name: 'Transport 3 – Origin – Destination, Weight, Emission per Trip (File)',
    shortDescription: 'Total data per shipment with calculated emissions.',
    type: WarehouseUploadType.EMISSIONS,
  },
  {
    category: DataCollectionTemplateCategory.TRANSPORT,
    description: ' Input monthly data sorted by temperature class and transport method.',
    format: DataCollectionTemplateFormat.MANUAL_ENTRY,
    id: DataCollectionTemplateId.TRANSPORT_4,
    name: 'Transport 4 – Monthly Total Volumes per Transport Mode (Entry)',
    shortDescription: 'Data, categorized by temperature class and transport method.',
    type: WarehouseUploadType.EMISSIONS,
  },
  {
    category: DataCollectionTemplateCategory.TRANSPORT,
    description: 'Input monthly totals data, temperature class, and transport method.',
    format: DataCollectionTemplateFormat.FILE_UPLOAD,
    id: DataCollectionTemplateId.TRANSPORT_5,
    name: 'Transport 5 – Weight, Distance per Trip (File)',
    shortDescription: 'Totals data for all shipments.',
    type: WarehouseUploadType.EMISSIONS,
  },
  {
    category: DataCollectionTemplateCategory.TRANSPORT,
    description: `Input monthly total data per shipment, temperature class, and transport method. Origin/destination or
     total distance has to be provided.`,
    format: DataCollectionTemplateFormat.FILE_UPLOAD,
    id: DataCollectionTemplateId.TRANSPORT_6,
    name: 'Transport 6 - Origin – Destination, Weight per Trip (File)',
    shortDescription: 'Total data per shipment.',
    type: WarehouseUploadType.EMISSIONS,
  },
  {
    category: DataCollectionTemplateCategory.WAREHOUSE,
    description: 'Input monthly cargo data sorted by temperature class and calculated emissions.',
    format: DataCollectionTemplateFormat.MANUAL_ENTRY,
    id: DataCollectionTemplateId.WAREHOUSE_1,
    name: 'Warehouse 1 – Monthly Total Volumes including Emissions (Entry)',
    shortDescription: 'Data, categorized by temperature class with calculated emissions.',
    type: WarehouseUploadType.EMISSIONS,
  },
  {
    category: DataCollectionTemplateCategory.WAREHOUSE,
    description: 'Input monthly cargo data sorted by temperature class.',
    format: DataCollectionTemplateFormat.MANUAL_ENTRY,
    id: DataCollectionTemplateId.WAREHOUSE_2,
    name: 'Warehouse 2 – Monthly Total Volumes (Entry)',
    shortDescription: 'Data, categorized by temperature class.',
    type: WarehouseUploadType.EMISSIONS,
  },
];

/**
 * List of Data Collection Statuses.
 *
 * @constant
 * @type {SelectOption<UploadStatus>[]}
 * @example [{key: 'SUCCESS', value: 'Success'}]
 */
export const dataCollectionStatusList: SelectOption<WarehouseUploadStatus>[] = Object
  .values(dataCollectionHistoryStatusMap).map(({ key, value }) => ({ key, value }));

/**
 * Mapping of template component IDs to their respective component types.
 *
 * This mapping includes references to specific Angular components associated with
 * certain template names.
 *
 * @constant
 * @type {{ [key: string]: ComponentType<TemplateComponents> }}
 * @see {@link https://bit.ly/3QDDzA4 Emission Templates Documentation}
 */
export const templateComponents: { [key: string]: ComponentType<TemplateComponents> } = {
  [DataCollectionTemplateId.TRANSPORT_1]: Transport1DialogComponent,
  [DataCollectionTemplateId.TRANSPORT_4]: Transport4DialogComponent,
  [DataCollectionTemplateId.WAREHOUSE_1]: Warehouse1DialogComponent,
  [DataCollectionTemplateId.WAREHOUSE_2]: Warehouse2DialogComponent,
};
