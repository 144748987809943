import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { first, Observable } from 'rxjs';

import { ListMessageTypesPayload, MessageType, ServiceList, TradingPartner } from '../../models';
import { ApiService } from '../../services';

const ENDPOINT = 'v1/partners';

@Injectable({ providedIn: 'root' })
export class TradingPartnersApiService implements ServiceList<TradingPartner[]> {
  constructor(private api: ApiService) {}

  list(): Observable<TradingPartner[]> {
    return this.api.get<TradingPartner[]>(ENDPOINT).pipe(first());
  }

  listMessageTypes(payload?: ListMessageTypesPayload): Observable<MessageType[]> {
    let api: Observable<MessageType[]> = this.api.get<MessageType[]>(`${ENDPOINT}/types`);

    if (payload?.excludeNonIntegratedTransactionTypes) {
      const params = new HttpParams().set('excludeNonIntegratedTransactionTypes', 'true');

      api = this.api.get<MessageType[]>(`${ENDPOINT}/types`, { params });
    }

    return api.pipe(first());
  }
}
