import { Exclude, Expose } from 'class-transformer';

import { DEFAULT_ALERT_CONDITION } from '@core/constants';
import { AlertKeyCondition } from '@core/enums';
import { IAlert, SelectOption, SimpleRecipient } from '@core/models';

import { AlertEventPipe } from '../pipes';

@Exclude()
export class Alert implements IAlert {
  @Expose()
    id!: string;

  @Expose()
    createdAt!: string;

  @Expose()
    updatedAt!: string | null;

  @Expose()
    name!: string;

  @Expose()
    event!: string;

  @Expose()
    conditions!: SelectOption<AlertKeyCondition>[];

  @Expose()
    recipients!: SimpleRecipient[];

  get transactionType(): string {
    const transactionType = this.conditions?.find((condition) => condition.key === AlertKeyCondition.TRANSACTION_TYPE);

    return transactionType?.value ?? DEFAULT_ALERT_CONDITION;
  }

  get senderId(): string {
    const senderName = this.conditions?.find((condition) => condition.key === AlertKeyCondition.SENDER);

    return senderName?.value ?? DEFAULT_ALERT_CONDITION;
  }

  get eventName(): string {
    return new AlertEventPipe().transform(this.event);
  }

  get recipientsNameList(): string[] {
    return this.recipients.map((recipient) => recipient.name ?? '');
  }
}
